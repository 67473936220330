<template>
  <v-container class="mt-8 ml-2 transparent elevation-0" fluid>
    <v-row class="pa-4">
      <v-col cols="12" sm="6" md="6" lg="4" class="pt-0 pr-0">
        <v-card class="transparent elevation-1">
          <!-- Seleccionaar el usuario -->
          <v-toolbar
            class="elevation-0"
            color="#00A548"
            dark
            dense
          >
            <v-autocomplete
              filled
              dense
              clearable
              v-model="usuario"
              :items="usuarios"
              label="Selecciona un usuario"
              hide-details
              return-object
              item-text="nombre_completo"
              item-value="whatsappservidor"
              single-line
              prepend-inner-icon="mdi-account"
              append-outer-icon="mdi-refresh"
              @click:append-outer="getChats()"
            >
            </v-autocomplete>
          </v-toolbar>

          <!-- SECCIÖN DE CHAT -->
          <v-card-text class="blue-grey lighten-5">
            <v-row>
              <v-col cols="10">
                <v-text-field
                  :readonly="filterChats ? false : true"
                  name="name"
                  label="Buscar chat"
                  v-model="buscarUsuario"
                  hide-details
                  dense
                  :autofocus="buscarUsuarioActive"
                  filled
                  single-line
                  prepend-inner-icon="mdi-magnify"
                  :append-outer-icon="notSeen ? 'mdi-filter' : 'mdi-filter-off'"
                  @click:append-outer="notSeen = !notSeen"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-btn icon @click="notResponse = !notResponse" class="pt-1">
                  <v-icon>{{ notResponse ? 'mdi-message-bulleted' : 'mdi-message-bulleted-off' }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>

          <!-- SECCIÓN DE FILTRO PARA WHATSAPP -->
          <v-card-text class="pa-0">
            <div v-if="notSeen">
              <v-chip
                class="ma-2"
                color="pink"
                label
                text-color="white"
              >
                <v-icon left>
                  mdi-account
                </v-icon>
                {{ filterChats.length }}
              </v-chip>

              <v-chip
                class="ma-2"
                color="cyan"
                label
                text-color="white"
              >
                <v-icon left>
                  mdi-message-bulleted
                </v-icon>
                {{ filterChats.map(item => item.cant_mensajes ).reduce((prev, curr) => prev + curr, 0) }}
              </v-chip>
            </div>


            <!-- SECCI*ON DE LOS CONTACTOS -->
            <div
              :style="`height: 610px; overflow: auto;`" 
              id="preguntas"
            >
              <v-progress-linear
                color="#00A548"
                indeterminate
                rounded
                height="4"
                v-if="loader && filterChats "
              ></v-progress-linear>

              <v-list dense>
                <template v-for="(chatW, i) in filterChats" :kei="i" v-if="i < 100">
                  <v-divider inset></v-divider>

                  <v-list-item
                    @click="verChat( chatW )"
                    :class="chatW == chat ? 'grey lighten-3' : '' "
                  >
                    <!-- AVATAR -->
                    <v-list-item-avatar>
                      <v-icon :color="getColorIcon( chatW )">mdi-account</v-icon>
                    </v-list-item-avatar>

                    <!-- contenido del contacto -->
                    <v-list-item-content>

                      <!-- Nombre del contacto -->
                      <v-list-item-title v-text="getNombre(chatW)"></v-list-item-title>

                      <v-list-item-subtitle >
                        <!-- VISTO -->
                        <v-icon 
                          small 
                          class="mr-1" 
                          v-if="chatW.ultimo_mensaje.length && chatW.ultimo_mensaje[0].fromMe" 
                          :color="chatW.ultimo_mensaje[0].ack < 3 ? 'grey' : 'blue'"
                        >
                          mdi-check-all
                        </v-icon>

                        <!-- MOSTRAR ULTIMO MENSAJE -->
                        <span>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              > {{ getMensaje( chatW ) }}</span>
                            </template>
                            <span>{{ getMensaje( chatW ) }}</span>
                          </v-tooltip>
                        </span>   

                      </v-list-item-subtitle>

                    </v-list-item-content>


                    <v-list-item-icon>
                      <!-- HORA DEL CHAT -->
                      <v-list-item-action-text >{{  parseUnix( chatW.timestamp ) }}</v-list-item-action-text>

                      <!-- Marcador para saber cuando mensaje no leídos son -->
                      <v-chip v-if="chatW.no_leidos.length " color="green" small dark class="pa-2">
                        {{ chatW.no_leidos.length }}
                      </v-chip>
                    </v-list-item-icon>

                    <!-- Marcador para saber si esta pineado o nel -->
                    <v-btn color="grey" absolute class="mt-5" icon right x-small v-if="chatW.pinned"><v-icon>mdi-pin</v-icon></v-btn>

                  </v-list-item>
                </template>
              </v-list>
            </div>
          </v-card-text>

        </v-card>
      </v-col>

      <!-- SECCIÓN DEL CHAT -->
      <v-col cols="12" sm="6" md="6" lg="8" class="pt-0 pl-0">

        <!-- MOSTRAR COMO QUIERA UN TOOLBAR SI NO HAY UN CHAT -->
        <v-card class="elevation-1" v-if="!chat">
          <v-toolbar
            class="elevation-0"
            color="#00A548"
            dark
            dense
          >
          </v-toolbar>
        </v-card>

        <!-- MOSTRAR SOLO SI HAY UN CHAT -->
        <v-card class="elevation-1" v-if="chat">
          <v-toolbar
            class="elevation-0"
            color="#00A548"
            dark
            dense
          >
            {{ chat.id.user + '@' + chat.id.server }}

            <v-spacer></v-spacer>
            <v-checkbox label="Todos" hide-details v-model="verTodosLosMensajes" :value="1"></v-checkbox>

            <!-- MENÚ DE LOS 3 PUNTOS PARA EL DESPLIEGUE DE OPCIONES -->
            <v-menu offset-y >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-3"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                  small
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense nav >
                <v-list-item link>
                  <v-list-item-title @click="parametrosAsignarVendedora.estatus = true">Asignar o reasignar</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>


          <section class="chat">
            <div 
              class="messages-chat" 
              :style="`max-height: 680px; overflow: auto;`" 
              id="mensajesChat"
            >
              <div id="scroll">
                <MensajesWpp  :mensajes="mensajes" :alumno="alumno" :wha="wha" :chat="chat" :verTodosLosMensajes="verTodosLosMensajes" :asignacion="asignacion"/>
              </div>
            </div>
          </section>
        </v-card>
      </v-col>


      <AsignarVendedora  :parametrosAsignarVendedora="parametrosAsignarVendedora" :alumno="alumno" :wha="wha" :chat="chat" :usuarios="usuarios" @socketAsignacion="socketAsignacion()"/>

      <!-- Dialog de carga -->
      <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
      <carga                v-if="cargar"/>

    </v-row>
    
  </v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters } from 'vuex'
  import { io }         from "socket.io-client";

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import MensajesWpp           from '@/components/whatsapp/MensajesWpp.vue';
  import AsignarVendedora      from '@/components/whatsapp/AsignarVendedora.vue';

  import validarErrores        from '@/mixins/validarErrores'

  export default {
    components:{
      Alerta,
      carga,
      MensajesWpp,
      AsignarVendedora
    },

    mixins: [ validarErrores ],


    data: () => ({
      buscarPregunta:'',
      buscarActive:false,

      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      cargar: false,

      usuarios:[],

      usuario: null,
      buscarUsuario:'',
      buscarUsuarioActive:true,
      alumno:null,
      url: 'https://escuelakpi.club/kpi/whatsapp-imagenes/',

      notSeen:false,
      searchMensaje:'',
      mensajes:[],
      chat:null,

      internos: ['5218115865223',  '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223', '5218115865223','5218121058094','5218180761486','5218111945886', '5218120790973'],

      wha: null,
      notResponse:false,

      verTodosLosMensajes: 0,
      message: '',

      parametrosAsignarVendedora:{
        estatus: false
      },
      asignacion: null,
      conversaciones:[],
      asignados:[],
      loader: true,

      sonido: null,
      socket: null,

    }),

    computed: {
      ...mapGetters('login',['getdatosUsuario']),

      filterChats(){
        if( this.usuario ){
          let chats = this.conversaciones 

          for( const i in chats ){
            // Sacar mensajes no leídos
            let { no_leidos } = chats[i]

            // Sacar realmente los no leídos
            chats[i].no_leidos     = no_leidos.filter( el => { return !el.fromMe })
            chats[i].cant_mensajes = no_leidos.filter( el => { return !el.fromMe }).length
          }

          chats   = chats.sort((a,b)=> (b.pinned - a.pinned ))

          if( this.notSeen ){ chats = chats.filter(el => { return el.no_leidos.length > 0 }) }

          if( this.notResponse ){ chats = chats.filter( el => { return el.ultimo_mensaje.length && !el.ultimo_mensaje[0].fromMe }) }

          // Verificamos que haya texto en buscar usuario
          if( this.buscarUsuario != '' ){

            return chats.filter( el => { 

              // separar el nombre y el id
              const name = el.name ? el.name : ''
              const id   = el.id ? el.id.user : ''

              // Buscar un match con el nombre o bien con el user que sería el número completo del usuario
              return name.match( this.buscarUsuario ) || id.match( this.buscarUsuario )

            }) 

          }else{ return chats }
        }
      },

    },

    watch:{
      usuario( val ){
        if( val ){ 
          // Asignamos el usuario al valor de wha
          this.wha = val; 

          // Obtenemos los chats de ese usuario de whatsApp
          this.getChats( val ); 

          // Obtener todos los whatsapp de la vendedora ( los asignados )
          this.getAsignados( val )
        }
      }
    },

    async created (){
      await this.initialize()
      this.crearTemplateSonido( )
    },

    mounted() {

      // Modo servidor
      this.socket = io(`https://whaencinbi.club/`, {
        transports: ["websocket"], path: '/ws/socket.io' 
      });

      // Modo local
      // this.socket = io(`ws://localhost:3005/`, {
      //   transports: ["websocket"], path: '/ws/socket.io' 
      // });

      this.socket.on("connect", ( ) => {
        // Conectar cocket
        console.log('connect')
        this.socket.emit()
      });

      this.socket.on("disconnect", () => {
        console.log('Desconectado del servidor');
      });

      this.socket.on('obtenerContactos', ( contactos ) => {

        this.conversaciones = contactos

        this.loader = false
      })


      /*
        Nueva asignación:
        -. Cuando se realiza una nueva asignación, el socket, debe escucharlo y cargar a los usuarios a los que tiene permiso de revisar
        -. También se debe poder mostrar el chat, entonces, son dos cosas las que se deben realizar
      */

      this.socket.on("nuevaAsignacion", () => {

        // Obtenemos los whatsapp de los que tenemos permiso recibir
        this.getAsignados( this.wha )

        // Obtener todos los chats
        this.getChats(  )
      });

      this.socket.on("mensajesCreados", ( mensaje ) => {

        if( mensaje.froms == '5218120474412@c.us' && this.wha || mensaje.message.to == '5218120474412@c.us' && this.wha){

          // Desestructurar datos
          const { fromMe, froms, message } = mensaje

          // Validar que no sea mio 
          if( !fromMe ){  

            // Buscar si el que mandó mensaje es de los que tengo acceso
            if( this.asignados.find( el => froms.match( el ) ) ){

              // Si es de los míos, sonar la campana de mensajes
              this.sonido.play()
            }

          }
          
          // Validar que el mensaje que llégó, sea el que esta abierto, si es así, hay que agregarlo al chat para que se refleje
          if( froms.match( this.chat.id.user ) || message.to.match( this.chat.id.user ) ){
            console.log('oihonh')
            this.verChat( this.chat )
          }

          // Obtener todos los chats
          this.getChats(  )
        }
      });
    },

    methods: {

      getAsignados( val ){
        // this.asignados = []

        // return this.$http.get('whatsapp.asignados/' + val.id_usuario ).then(response=>{
          
        //   // Guardar los datos regresados
        //   this.asignados = response.data.map(( { id_whatsapp } ) => { return id_whatsapp })

        // }).catch( error =>{

        //   // Validar el error del servidor
        //   this.validarError( error.response.data.message )

        // }).finally( () => { this.cargar = false })
      },

      initialize () {
        this.cargar = true
        this.usuarios = []
        return this.$http.get('whatsapp.usuarios').then(response=>{
          this.usuarios = response.data
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getChats( ){

        // Validamos que haya un usuario asignado al whatsapp
        if( !this.wha ){ return this.validarErrorDirecto('Favor de seleccionar un Usuario') }

        // Preparamos el paylos para ser enviado
        let payload = { from: this.wha.whatsappservidor }

        // Emitimos el socket para que cargue la información e indicamos que esta cargando la info
        this.loader = true

        // Generando la url del post
        const url = this.wha.url_servidor + 'whatsapp.chats'

        return this.$http.post(url, payload ).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      socketAsignacion( ){
        this.socket.emit('nuevaAsignacion', ( resp ) => {
          console.log('Nueva asignación')
        })
      },

      verChat( chat ){
        this.chat = chat

        const payload = {
          whaid      : chat.id.user +'@'+ chat.id.server,
          id_whatsapp: chat.id.user,
          escuela    : this.usuario.plantel.match('FAST') ? 2 : 1
        }

        this.cargar   = true
        this.mensajes = []

        return this.$http.post('whatsapp.chat.telefono', payload ).then(response=>{
          console.log( response.data )
          this.mensajes   = response.data.mensajesWha
          this.asignacion = response.data.getAsignacion

          setTimeout(function(){document.getElementById('mensajesChat').scrollTop=document.getElementById('mensajesChat').scrollHeight},1000);
          this.cargar   = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      parseUnix( value ){
        var date = new Date( value * 1000);

        var date = (new Date(new Date( value * 1000) - (new Date()).getTimezoneOffset() * 60000))

        return value ? date.toISOString().substr(0, 10) + ' ' + date.toISOString().substr(11, 5) : 'Sin fecha'
      },

      getNombre( texto ){
        return this.internos.includes(texto.id.user ) ? 'Interno ' + texto.name : texto.name 
      },

      getMensaje( texto ){
        if( texto.ultimo_mensaje.length ){
          if( texto.ultimo_mensaje[0].type == 'chat')
            return texto.ultimo_mensaje[0].body
          else 
            return texto.ultimo_mensaje[0].type
        }

        return ''
      },

      getColorIcon( chat ){
        return this.internos.includes( chat.id.user ) ?  'red' : 'primary'
      },

      crearTemplateSonido( ){
        console.log('audio.ogg')

        this.sonido = document.createElement("audio");

        this.sonido.src = "whatsapp-campana.mp3";

        this.sonido.setAttribute("preload", "auto");
        this.sonido.setAttribute("controls", "none");
        this.sonido.style.display = "none"; // <-- oculto
        document.body.appendChild(this.sonido);

      },
    },
  }
</script>

<style  scoped>

  #mensajesChat{
    background-image: url("/fondo_whatsapp.jpg");
    background-repeat: repeat-y;
    background-repeat: repeat-x;
    background-size:contain;
  }

  #preguntas::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > #preguntas::-webkit-scrollbar:vertical {
    width: 4px !important;
  }

  #preguntas::-webkit-scrollbar:vertical{
    width: 4px !important;
  }

  #preguntas::-webkit-scrollbar-button:increment,#preguntas::-webkit-scrollbar-button{
    display: none;
  }

  #preguntas::-webkit-scrollbar:horizontal{
    display: none;
  }

  #preguntas::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

  /**********************************/

  #mensajesChat::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > #mensajesChat::-webkit-scrollbar:vertical {
    width: 4px !important;
  }

  #mensajesChat::-webkit-scrollbar:vertical{
    width: 4px !important;
  }

  #mensajesChat::-webkit-scrollbar-button:increment,#mensajesChat::-webkit-scrollbar-button{
    display: none;
  }

  #mensajesChat::-webkit-scrollbar:horizontal{
    display: none;
  }

  #mensajesChat::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

  .container {
    padding:0;
    background-color: #FFF; 
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    height: 750px;
    margin-top: 25px;
  }

  /* ===== MENU ===== */
  .menu {
    float: left;
    height: 700px;;
    width: 70px;
    background: #4768b5;
    background: -webkit-linear-gradient(#4768b5, #35488e);
    background: -o-linear-gradient(#4768b5, #35488e);
    background: -moz-linear-gradient(#4768b5, #35488e);
    background: linear-gradient(#4768b5, #35488e);
    box-shadow: 0 10px 20px rgba(0,0,0,0.19);
  }

  .menu .items {
    list-style:none;
    margin: auto;
    padding: 0;
  }

  .menu .items .item {
    height: 70px;
    border-bottom: 1px solid #6780cc;
    display:flex;
    justify-content: center;
    align-items: center;
    color: #9fb5ef;
    font-size: 17pt;
  }

  .menu .items .item-active {
    background-color:#5172c3;
    color: #FFF;
  }

  .menu .items .item:hover {
    cursor: pointer;
    background-color: #4f6ebd;
    color: #cfe5ff;
  }

  /* === CONVERSATIONS === */

  .discussions {
    width: 100%;
    height: 702px;
    box-shadow: 0px 8px 10px rgba(0,0,0,0.20);
    overflow: hidden;
    display: inline-block;
  }

  .discussions .discussion {
    width: 100%;
    height: 90px;
    background-color: #FAFAFA;
    display:flex;
    align-items: center;
    cursor: pointer;
  }

  .discussions .search {
    display:flex;
    align-items: center;
    justify-content: center;
    color: #E0E0E0;
  }

  .discussions .search .searchbar {
    height: 40px;
    background-color: #FFF;
    width: 70%;
    padding: 0 20px;
    border-radius: 50px;
    border: 1px solid #EEEEEE;
    display:flex;
    align-items: center;
    cursor: pointer;
  }

  .discussions .search .searchbar input {
    margin-left: 15px;
    height:38px;
    width:100%;
    border:none;
    font-family: 'Montserrat', sans-serif;;
  }

  .discussions .search .searchbar *::-webkit-input-placeholder {
      color: #E0E0E0;
  }
  .discussions .search .searchbar input *:-moz-placeholder {
      color: #E0E0E0;
  }
  .discussions .search .searchbar input *::-moz-placeholder {
      color: #E0E0E0;
  }
  .discussions .search .searchbar input *:-ms-input-placeholder {
      color: #E0E0E0;
  }

  .discussions .message-active {
    height: 90px;
    background-color: #FFF;
    border-right: solid 5px #E91E63;
  }

  .discussions .discussion .photo {
    margin-left:20px;
    display: block;
    width: 45px;
    height: 45px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .online {
    position: relative;
    top: 30px;
    left: 35px;
    width: 13px;
    height: 13px;
    background-color: #8BC34A;
    border-radius: 13px;
    border: 3px solid #FAFAFA;
  }

  .desc-contact {
    height: 43px;
    width:50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .discussions .discussion .name {
    margin: 0 0 0 20px;
    font-family:'Montserrat', sans-serif;
    font-size: 11pt;
    color:#515151;
  }

  .discussions .discussion .message {
    margin: 6px 0 0 20px;
    font-family:'Montserrat', sans-serif;
    font-size: 9pt;
    color:#515151;
  }

  .timer {
    margin-left: 15%;
    font-family:'Open Sans', sans-serif;
    font-size: 11px;
    padding: 3px 8px;
    color: #BBB;
    background-color: #FFF;
    border: 1px solid #E5E5E5;
    border-radius: 15px;
  }

  .chat {
    width: calc(100%);
  }

  .chat .messages-chat {
    padding: 25px 10px;
  }

  .chat .messages-chat .message {
    display:flex;
    align-items: center;
    margin-bottom: 2px;
  }

  .chat .messages-chat .message .photo {
    display: block;
    width: 45px;
    height: 45px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .chat .messages-chat .text {
    margin: 0 35px;
    background-color: #f6f6f6;
    padding: 15px;
    border-radius: 12px;
  }

  .text-only {
    white-space: pre-line;
  }

  .time {
    font-size: 12px;
    color:lightgrey;
    margin-bottom:10px;
    margin-left: 38px;
  }

  .response-time {
    float: right;
    margin-right: 40px !important;
  }

  .response {
    float: right;
    margin-right: 0px !important;
    margin-left: auto; /* flexbox alignment rule */
    max-width: 90%;
  }

  .noresponse {
    max-width: 90%;
  }

  .response .text {
    background-color: #E91E63 !important;
    color: white;
  }

  .footer-chat {
    width: calc(65% - 66px);
    height: 80px;
    display:flex;
    align-items: center;
    position:absolute;
    bottom: 0;
    background-color: transparent;
    border-top: 2px solid #EEE;
    
  }

  .chat .footer-chat .icon {
    margin-left: 30px;
    color:#C0C0C0;
    font-size: 14pt;
  }

  .chat .footer-chat .send {
    color:#fff;
    background-color: #4f6ebd;
    position: absolute;
    right: 50px;
    padding: 12px 12px 12px 12px;
    border-radius: 50px;
    font-size: 14pt;
  }

  .chat .footer-chat .name {
    margin: 0 0 0 20px;
    text-transform: uppercase;
    font-family:'Montserrat', sans-serif;
    font-size: 13pt;
    color:#515151;
  }

  .chat .footer-chat .right {
    position: absolute;
    right: 40px;
  }

</style>
